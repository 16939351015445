import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import './interpreterSearch.css'
import { AXIOSLINK } from '../../utils/constants'
import PersonList from './personList'
const axios = require('axios')
import { isEmpty } from "lodash";
import ReactMarkdown from 'react-markdown'
var zipcodes = require('zipcodes');


import {
  MDBBtn,
  MDBIcon,
} from 'mdbreact'

const IsridInterpreterSearch = () => {
  const data = useStaticQuery(graphql`
{
      allStrapiInterpreterSearches {
        nodes {
          City
          FirstName
          LastName
          SearchbyName
          SearchbyRadius
          State
          ZIPCODE
          InterpreterSearchDynamic {
            Description
            TitleAlignment
            TitleFontColor
            TitleFontFamily
            TitleFontSize
            TitleFontWeight
            TitleName
          }
          WithIn {
            _100_Miles
            _10_Miles
            _25_Miles
            _50_Miles
          }
        }
      }
    }
  `)



    const initialFormData = Object.freeze({
      FirstName: '',
      LastName: '',
      city: '',
      state: '',
      zipcode: '',
      within:'',
      

    })
    const [formData, updateFormData] = React.useState(initialFormData)
    const handleChange = e => {
      updateFormData({
        ...formData,

        // Trimming any whitespace
        [e.target.name]: e.target.value.trim(),
      })
    }


  var IsridInterpreterSearch = data.allStrapiInterpreterSearches.nodes
  var IsridInterpreterSearchDynamic =
    data.allStrapiInterpreterSearches.nodes[0].InterpreterSearchDynamic

  function showSearchbyRadius() {
    document.getElementById('SearchbyName').hidden = true
    document.getElementById('SearchbyRadius').hidden = false
  }
  function showSearchbyName() {
    document.getElementById('SearchbyName').hidden = false
    document.getElementById('SearchbyRadius').hidden = true

  }

  function nameSearch() {
    const data = {
      FirstName : formData.FirstName,
      LastName : formData.LastName,
    }
    console.log(data)
    if(isEmpty(data.FirstName) == true && isEmpty(data.LastName) == true){
      var message = document.getElementById("messageforname")
      message.innerHTML="Please fill the required field."
      message.style.color="red"
    }
    else{
    sessionStorage.setItem("callNameSearch","true");
    sessionStorage.setItem("FIRSTNAME",data.FirstName);
    sessionStorage.setItem('LASTNAME', data.LastName);
    window.location.pathname='/InterpreterResult'
    }
  }

  function codeSearch(){

    
    var CheckState = document.getElementById("Checkstate").value
    var CheckWithin = document.getElementById("within").value
    var CheckCity = document.getElementById("checkCity").value
    var CheckZipcode = document.getElementById("checkZipcode").value


    const data={
      Zipcode : CheckZipcode,
      within : CheckWithin,
      state : CheckState,
      city: CheckCity,
    }

    console.log(data)

    if(isEmpty(data.Zipcode)== false && isEmpty(data.city)==true && isEmpty(data.within)==false && isEmpty(data.state)==false ){
      var rad = zipcodes.radius(data.Zipcode,data.within );
      if(rad.length  == 0){
        var message = document.getElementById("messageforradius")
        message.innerHTML="Given Zipcode is Invalid!"
        message.style.color="red"
      }
      else{
        sessionStorage.setItem('CodeWithinSearchData', JSON.stringify(data))
        sessionStorage.setItem("callwithipcode","true");
        window.location.pathname='/InterpreterResult'
      }
    
    }

    // if(isEmpty(data.Zipcode)==false && isEmpty(data.city)==true &&  isEmpty(data.state)==false && isEmpty(data.within)==true){
    //   sessionStorage.setItem('CodeSearchData', data.Zipcode)
    //   sessionStorage.setItem("callCodeSearch","true");
    //   window.location.pathname='/InterpreterResult'
    // }

    // if(isEmpty(data.Zipcode)==true && isEmpty(data.within)==false && isEmpty(data.state)==false && isEmpty(data.city)==true ){
    //   sessionStorage.setItem('StateCitySearchData',JSON.stringify(data) )
    //   sessionStorage.setItem("callStateCitySearch","true");
    //   window.location.pathname='/InterpreterResult'
    // }
    
    if(isEmpty(data.within)==false && isEmpty(data.state)==false && isEmpty(data.city)==false && isEmpty(data.Zipcode)==true ){
      var checkstatecity = zipcodes.lookupByName(data.city, data.state);
      if(checkstatecity.length == 0){
      var message = document.getElementById("messageforradius")
      message.innerHTML="Given city not matching with state!"
      message.style.color="red"
      }
      else{
      sessionStorage.setItem('WithInStateCitySearchData',JSON.stringify(data) )
      sessionStorage.setItem("callWithinStateCitySearch","true");
      window.location.pathname='/InterpreterResult'
      }
    }

    // if(isEmpty(data.Zipcode)==true && isEmpty(data.state)==true && isEmpty(data.city)==true && isEmpty(data.within)==true){
    //     var message = document.getElementById("messageforradius")
    //     message.innerHTML="Please fill the required field."
    //     message.style.color="red"
    // }
    // if(isEmpty(data.state)==true && isEmpty(data.city)==false && isEmpty(data.within)==false){
    //     var message = document.getElementById("messageforradius")
    //     message.innerHTML="Please fill the State field."
    //     message.style.color="red"
    // }
    // if(isEmpty(data.state)==false && isEmpty(data.city)==true && isEmpty(data.within)==false){
    //   var message = document.getElementById("messageforradius")
    //   message.innerHTML="Please fill the City field."
    //   message.style.color="red"
    // }
    if(isEmpty(data.state)==false && isEmpty(data.city)==false && isEmpty(data.Zipcode)==false && isEmpty(data.within)==false){
      var message = document.getElementById("messageforradius")
      message.innerHTML="Please try with either Zipcode or City."
      message.style.color="red"
    }
    if(isEmpty(data.state)==false && isEmpty(data.city)==true && isEmpty(data.Zipcode)==true && isEmpty(data.within)==false){
      var message = document.getElementById("messageforradius")
      message.innerHTML="Please Enter Zipcode or City."
      message.style.color="red"
    }
  }

  return (
    <div>
      {/* ********************* */}

      <div className="container">
        <div className="center" >
          <h1 style={{ paddingBottom: '3%' ,textAlign: IsridInterpreterSearchDynamic[0].TitleAlignment, color:IsridInterpreterSearchDynamic[0].TitleFontColor,fontFamily:IsridInterpreterSearchDynamic[0].TitleFontFamily,fontSize:IsridInterpreterSearchDynamic[0].TitleFontSize,fontWeight:IsridInterpreterSearchDynamic[0].TitleFontWeight}}>
            {IsridInterpreterSearchDynamic[0].TitleName}
          </h1>
          <h5 style={{ paddingBottom: '3%' ,lineHeight: '1.6',
          textAlign: 'justify',
          textJustify: 'inter-word'}}>
          <ReactMarkdown source={IsridInterpreterSearchDynamic[1].Description} />
          </h5>
        </div>

        <div>
          <input
            type="radio"
            required
            name="interpreter"
            onClick={showSearchbyRadius}
            id="interpreterRadius"
            checked
          />
          &nbsp;&nbsp;
          <label for="interpreterRadius" style={{ paddingRight: '20px' }}>
            <b style={{ fontSize: '17px' }}>
              {IsridInterpreterSearch[0].SearchbyRadius}
            </b>
          </label>
          <br />
          <input
            type="radio"
            required
            name="interpreter"
            onClick={showSearchbyName}
            id="interpreterName"
            
          />
          &nbsp;&nbsp;
          <label for="interpreterName" >
            <b style={{ fontSize: '17px' }}>
              {IsridInterpreterSearch[0].SearchbyName}
            </b>
          </label><br /><br />
        </div>
        {/* ******************** */}
        <div id="SearchbyRadius">
        <div class="form-inline">
        <div class="inputBox"></div>
          <label htmlFor="zip">
            <b>{IsridInterpreterSearch[0].ZIPCODE}:</b>
          </label>&nbsp;
          <input
            type="number"
            class="form-control "
            placeholder="Enter ZIP"
            name="zipcode"
            id="checkZipcode"
            onChange={handleChange}
            required
            minlength="2"
            maxlength="6"
          />
          <p class="or" style={{paddingLeft:"10px"}}> <b>Or</b> </p>

          <label htmlFor="city" class="label">
            <b>{IsridInterpreterSearch[0].City}:</b>
          </label>&nbsp;
          <input
            type="text"
            class="form-control"
            placeholder="Enter city"
            name="city"
            id="checkCity"
            onChange={handleChange}
            required
            minlength="2"
            maxlength="15"
            
          />
          
          <label htmlFor="state" class="label">
            <b>{IsridInterpreterSearch[0].State}:</b>
          </label>&nbsp;
          <select
            className="Select form-control"
            name="state"
            id='Checkstate'
            onChange={handleChange}
          >
            <option value="Iowa"  >Iowa</option>
            {/* <option hidden></option> */}
            {/* <option value="Alabama">Alabama</option>
            <option value="Alaska">Alaska</option>
            <option value="Arizona">Arizona</option>
            <option value="Arkansas">Arkansas</option>
            <option value="California">California</option>
            <option value="Colorado">Colorado</option>
            <option value="Connecticut">Connecticut</option>
            <option value="Delaware">Delaware</option>
            <option value="District Of Columbia">District Of Columbia</option>
            <option value="Florida">Florida</option>
            <option value="Georgia">Georgia</option>
            <option value="Hawaii">Hawaii</option>
            <option value="Idaho">Idaho</option>*/}
            <option value="Illinois">Illinois</option>
            {/* <option value="Indiana">Indiana</option>  */}
            
            <option value="Kansas">Kansas</option>
            {/* <option value="Kentucky">Kentucky</option>
            <option value="Louisiana">Louisiana</option>
            <option value="Maine">Maine</option>
            <option value="Maryland">Maryland</option>
            <option value="Massachusetts">Massachusetts</option>
            <option value="Michigan">Michigan</option> */}
            <option value="Minnesota">Minnesota</option>
            {/* <option value="Mississippi">Mississippi</option> */}
            <option value="Missouri">Missouri</option>
            {/* <option value="Montana">Montana</option> */}
            <option value="Nebraska">Nebraska</option>
            {/* <option value="Nevada">Nevada</option>
            <option value="New Hampshire">New Hampshire</option>
            <option value="New Jersey">New Jersey</option>
            <option value="New Mexico">New Mexico</option>
            <option value="New York">New York</option>
            <option value="North Carolina">North Carolina</option>
            <option value="North Dakota">North Dakota</option>
            <option value="Ohio">Ohio</option>
            <option value="Oklahoma">Oklahoma</option>
            <option value="Oregon">Oregon</option>
            <option value="Pennsylvania">Pennsylvania</option>
            <option value="Rhode Island">Rhode Island</option>
            <option value="South Carolina">South Carolina</option>
            <option value="South Dakota">South Dakota</option>
            <option value="Tennessee">Tennessee</option>
            <option value="Texas">Texas</option>
            <option value="Utah">Utah</option>
            <option value="Vermont">Vermont</option>
            <option value="Virginia">Virginia</option>
            <option value="Washington">Washington</option>
            <option value="West Virginia">West Virginia</option>
            <option value="Wisconsin">Wisconsin</option>
            <option value="Wyoming">Wyoming</option> */}
          </select>
          
          </div>
          <label htmlFor="within" style={{ paddingTop: '20px' }}>
            <b>WithIn :</b>
          </label>&nbsp;
          <select className="Select form-control" name='within' id="within" onChange={handleChange}  >
            {/* <option hidden></option> */}
            <option value="10">{IsridInterpreterSearch[0].WithIn._10_Miles}</option>
            <option value="25" selected>{IsridInterpreterSearch[0].WithIn._25_Miles}</option>
            <option value="50">{IsridInterpreterSearch[0].WithIn._50_Miles}</option>
            <option value="100">{IsridInterpreterSearch[0].WithIn._100_Miles}</option>
          </select>
          
          <div className="text-center mt-4">
              <button type="submit" class="btn btn-success interpreterbtn" onClick={codeSearch}
              style={{backgroundColor:"rgb(0, 128, 0)",
              borderRadius:"7px",
              fontSize:"16px",
              width:"25%"}}>Click to Search </button>
          </div>
          <p id="messageforradius"></p>
        </div>

        {/* *********** */}

        <div id="SearchbyName" hidden="true">
          <label htmlFor="firstname" >
            <b>{IsridInterpreterSearch[0].FirstName}</b>
          </label>
          <input
            type="text"
            class="form-control"
            placeholder="Search by First name"
            name="FirstName"
            onChange={handleChange}
            id="userFirstName"
            minlength="2"
            maxlength="20"
          />
          <label htmlFor="lastname" style={{ paddingTop: '10px' }}>
            <b>{IsridInterpreterSearch[0].LastName}</b>
          </label>
          <input
            type="text"
            class="form-control"
            placeholder="Search by Last name"
            name="LastName"
            onChange={handleChange}
            id="userLastName"
            minlength="2"
            maxlength="20"
          />
          <div className="text-center mt-4">
              <button type="submit" class="btn btn-success interpreterbtn" onClick={nameSearch}
              style={{backgroundColor:"rgb(0, 128, 0)",
              borderRadius:"7px",
              fontSize:"16px",
              width:"25%"}}>Click to Search </button>
          </div>
          <p id="messageforname"></p>

        </div>

        {/* ********** */}
      </div>
    </div>
  )
}

export default IsridInterpreterSearch
