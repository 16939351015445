import React from 'react'
import '../components/InterpreterSearch/interpreterSearch.css'
import IsridInterpreterSearch from '../components/InterpreterSearch/IsridInterpreterSearch'

import Layout from '../components/layout'

const SearchInterpreter = ()=>{

 return (
    <Layout>
      <div
        className="card interpreter_card"
        style={{ backgroundColor: '#F9F9FF' }}
      >

        <div id="IsridInterpreterSearch">
          <IsridInterpreterSearch />
        </div>
      </div>
    </Layout>
  )
}

export default SearchInterpreter